.home {
  h1,
  h2 {
    @apply text-base font-medium @lg:text-xl;
  }

  h3 {
    @apply text-base font-medium @lg:text-lg;
  }

  p {
    @apply text-sm;
  }
}
